import { useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { FormProvider, useForm } from "react-hook-form";
import { Button, Stack } from "@mui/material";

import {
  deleteRoomGallery,
  getCompanyNames,
  getManagers,
  getRoomDetails,
  updateRoomDetails,
  updateRoomGallery,
} from "@requests";
import { ROUTES } from "@constants";
import { Loader } from "@atoms";
import { LeftSidabarSection } from "@molecules";
import { LeaveModalContainer } from "@templates";
import {
  RoomDetails,
  RoomFeatures,
  RoomGallery,
  RoomLimitAccess,
} from "@features";
import { palette } from "@styles/palette";
import { useErrorsToast, useFeaturesDictionary } from "@hooks";
import { displayToastSuccess, serverErrorHandler } from "@helpers";

import {
  createRoomFormValues,
  getCompaniesList,
  getFeaturesList,
  getManagersList,
  prepareFormValues,
} from "./utils";

const Room = () => {
  const { roomId } = useParams();

  const { isLoading, data: roomData } = useQuery({
    queryKey: ["room", roomId],
    queryFn: () => getRoomDetails(roomId),
  });

  const { isLoading: isFeaturesLoading, data: featuresData } =
    useFeaturesDictionary();

  const {
    roomNumber,
    floor,
    workstationCount,
    creditsPerHour,
    pricePerHour,
    salesforceUrl,
    googleCalendarLink,
    gallery,
    isComboRoom,
    location,
    cmNotificationEnabled,
    status,
  } =
    // TODO: fix this issue
    // eslint-disable-next-line no-unsafe-optional-chaining
    roomData?.data || {};

  const { data: companiesData } = useQuery({
    queryKey: ["companies"],
    queryFn: getCompanyNames,
  });

  const { data: managersData } = useQuery({
    queryKey: ["managers"],
    queryFn: getManagers,
  });

  const queryClient = useQueryClient();

  const {
    mutate: mutateRoomDetails,
    isSuccess: isSuccessfulUpdate,
    isLoading: isUpdateLoading,
  } = useMutation(updateRoomDetails, {
    onSuccess: async () => {
      displayToastSuccess("Success", "Room is updated");
      await queryClient.invalidateQueries(["room", roomId]);
      await queryClient.invalidateQueries(["rooms"]);
    },
    onError: serverErrorHandler,
  });

  const { mutate: mutateGallery } = useMutation(updateRoomGallery, {
    onError: serverErrorHandler,
  });

  const { mutate: deleteGallery } = useMutation(deleteRoomGallery, {
    onError: serverErrorHandler,
  });

  const roomValues = useMemo(
    () => createRoomFormValues(roomData?.data),
    [roomData]
  );

  const methods = useForm({
    defaultValues: {
      features: [],
      companyLimits: [],
      communityManagerLimits: [],
      cmNotificationEnabled: true,
      status: true,
    },
    values: roomValues,
  });

  const {
    reset,
    handleSubmit,
    formState: { isDirty, errors },
  } = methods;

  useErrorsToast(errors, "Rooms");

  const [showLeaveModal, setShowLeaveModal] = useState(
    isDirty && !isUpdateLoading
  );

  const managersList = useMemo(
    () => getManagersList(managersData?.data),
    [managersData]
  );

  const companiesList = useMemo(
    () => getCompaniesList(companiesData?.data),
    [companiesData]
  );

  const featuresList = useMemo(
    () => getFeaturesList(featuresData?.data),
    [featuresData?.data]
  );

  const onSubmit = async (values) => {
    const { gallery: preparedGallery, ...preparedValues } =
      prepareFormValues(values);

    await Promise.all(
      preparedGallery.map((formData) =>
        formData.get("image") === "null"
          ? deleteGallery({ id: roomId, position: formData.get("position") })
          : mutateGallery({ id: roomId, data: formData })
      )
    );

    // We should wait for all gallery mutations before updating the room details
    await mutateRoomDetails({ id: roomId, data: preparedValues });
  };

  useEffect(() => {
    setShowLeaveModal(isDirty && !isUpdateLoading);
  }, [isDirty, isUpdateLoading]);

  useEffect(() => {
    if (isSuccessfulUpdate) {
      reset({}, { keepValues: true });
      setShowLeaveModal(false);
    }
  }, [isSuccessfulUpdate, reset]);

  // TODO: create a HOC component and handle loader logic there
  if (isLoading || isFeaturesLoading) {
    return (
      <Loader
        position="fixed"
        background={palette.white}
        width={64}
        height={64}
      />
    );
  }

  return (
    <LeaveModalContainer when={showLeaveModal}>
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Stack direction="row">
            <LeftSidabarSection
              prevPageButtonContent="Back to Meeting Rooms"
              prevPageRoute={ROUTES.Rooms}
              title={`Room ${roomNumber}`}
            >
              <RoomDetails
                googleCalendarLink={googleCalendarLink}
                cmNotificationEnabled={cmNotificationEnabled}
                isComboRoom={isComboRoom}
                salesforceUrl={salesforceUrl}
                locationTitle={location?.title}
                floor={floor}
                workstationCount={workstationCount}
                creditsPerHour={creditsPerHour}
                pricePerHour={pricePerHour}
                currency={location?.currency}
                status={status}
              />
              <Button type="submit" variant="contained" sx={{ mt: 5 }}>
                Save
              </Button>
            </LeftSidabarSection>
            <Stack spacing={10} pt={12} pb={12} pr={12} pl={12}>
              <RoomGallery gallery={gallery} />
              <RoomFeatures features={featuresList} />
              <RoomLimitAccess
                companiesList={companiesList}
                managersList={managersList}
              />
            </Stack>
          </Stack>
        </form>
      </FormProvider>
    </LeaveModalContainer>
  );
};

export default Room;
